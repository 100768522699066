const Home =()=>{
    return(
        <>

        <div className="flex flex-col m-auto text-center text-9xl py-5 align-middle text-slate-700 drop-shadow-md kmail tracking-tighter ">
          <div className="flex">  km<div className="text-slate-900 drop-shadow-lg text-6xl  tracking-tighter text-red-700 mt-5 font-bold translate-y-3 -translate-x-5">  A </div>
          <div className="text-slate-900 drop-shadow-lg text-9xl  tracking-tighter text-red-700 mt-1 -translate-x-5">  i </div> l
          </div>
          

 
 <div className="flex align-baseline  text-lg tracking-widest pt-0 m-auto">

                +43 664 154 02 70
            </div>
            <div className="flex m-auto  text-3xl tracking-widest text-center font-semibold text-red-600 ">

                Vienna
            </div>
            </div>
           
        </>
    )
}
export default Home