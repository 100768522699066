import Home
 from './pages/home';
import './App.css';

function App() {
  return (
    <div className="flex h-svh">
<Home/>
    </div>
  );
}

export default App;
